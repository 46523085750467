<template>

  <v-container id="createResponse" class="h-100">

    <div class="py-3 mb-5 unselectable">
      <div class="top-text my-3">
        <h1 class="title is-1 thin black--text">Может</h1>
        <h1 class="title is-1 black--text">везём?</h1>
      </div>
    </div>

    <div class="remaining-space">

      <b-field label="Комментарий">
        <b-input v-model="request.comment" type="textarea"></b-input>
      </b-field>

      <b-field label="Цена">
        <b-input v-model="request.price" type="number"></b-input>
      </b-field>

    </div>

    <div class="pa-3 pb-0" style="text-align: center;">
        <p class="buttons mb-0" style="display: flex; justify-content: space-between;">
            <button class="button is-light is-medium" @click="$router.push('/orders/search')">
                <span>Назад</span>
            </button>

            <button class="button is-light is-medium" @click="submitForm">
                <span>Отправить</span>
                <span class="icon is-medium">
                    <i class="fas fa-chevron-right"></i>
                </span>
            </button>
        </p>
    </div>

  </v-container>

</template>

<script>

export default {
  name: 'OrderCreating2',
  data() {
    return {
      request: {
        comment: '',
        price: null,
      }
    };
  },
  created() {
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },
  methods: {
    submitForm() {
      if (this.request.price === null ||
        this.request.price === 0
      ) {
        this.$buefy.toast.open({
          message: 'Заполните все обязательные поля',
          type: 'is-danger',
        });
      } else {
        let newOffer =
          {
            "order_id": this.orderId,
            "price": this.request.price,
            "comment": this.request.comment
          };

        this.$store.dispatch('CREATE_OFFER', newOffer).then(() => {
          this.$buefy.toast.open({
            message: 'Отклик отправился клиенту!',
            type: 'is-success'
          });

          this.request = {
            comment: '',
            price: null,
          }

          this.$store.dispatch('GET_AVAILABLE_ORDERS_TRANSPORTER');
          this.$router.push('/orders/search');

        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка при создании предложения',
            type: 'is-danger'
          });
        });
      }
    }
  }
};

</script>

<style scope>
#createResponse .title.is-1 {
  font-size: 4.5rem !important;
}

#createResponse .title.is-1.thin {
  font-weight: 300;
}

#createResponse.container {
  display: flex;
  flex-direction: column;
}

.remaining-space {
  flex-grow: 1;
}
</style>
